import React from "react"
import { Link } from "gatsby"
import useLibrarySearch from "./use-library-search"

const Search = () => {
  const { setQuery, results, handleSubmit, query } = useLibrarySearch()
  return (
    <div>
      <div style={{ margin: "0 auto" }}>
        <form onSubmit={handleSubmit}>
          <div style={{ margin: "0 auto" }}>
            <label htmlFor="Search" style={{ paddingRight: "10px" }}>
              Enter your search here
            </label>
            <input
              id="Search"
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder="Enter your search here"
              style={{ margin: "0 auto", width: "400px" }}
            />
          </div>
        </form>
        <div>
          Number of items:
          {results.length}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              borderRadius: "4px",
              border: "1px solid #d3d3d3",
            }}
          >
            <thead style={{ border: "1px solid #808080" }}>
              <tr>
                <th
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderBottom: "2px solid #d3d3d3",
                    cursor: "pointer",
                  }}
                >
                  Direct Link
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderBottom: "2px solid #d3d3d3",
                    cursor: "pointer",
                  }}
                >
                  Title
                </th>
                <th
                  style={{
                    textAlign: "left",
                    padding: "5px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderBottom: "2px solid #d3d3d3",
                    cursor: "pointer",
                  }}
                >
                  Author
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map(item => {
                return (
                  <tr key={`row_${item.link}`}>
                    <td
                      style={{
                        fontSize: "14px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      <Link to={item.link}>{item.title}</Link>
                    </td>
                    <td
                      style={{
                        fontSize: "14px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      {item.title}
                    </td>
                    <td
                      style={{
                        fontSize: "14px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      {item.author}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Search
