import { useState, useEffect, useMemo, useCallback } from "react"
import * as JsSearch from "js-search"

const plugInData = [
  {
    link: `/artificial-intelligence/`,
    title: "Artificial Intelligence",
    author: "Dante Vella",
  },
  {
    link: `/Map/`,
    title: "GPS Location",
    author: "Dante Vella",
  },
]

const useLibrarySearch = () => {
  // state to access within this hook
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const [index, setIndex] = useState()

  useEffect(() => {
    //builds the search index
    const dataToSearch = new JsSearch.Search("link")
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("link")
    dataToSearch.addIndex("title")
    dataToSearch.addIndex("author")
    dataToSearch.addDocuments(plugInData)
    setIndex(dataToSearch)
  }, [])

  useEffect(() => {
    // sets results when index or query changes
    if (index) {
      setResults(index.search(query))
    }
  }, [query, index])

  const handleSubmit = useCallback(e => e.preventDefault(), [])

  const queryResults = useMemo(() => {
    if (query === "") return plugInData
    return results
  }, [query, results])

  return { setQuery, results: queryResults, handleSubmit, query }
}

export default useLibrarySearch
