import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SearchContainer from "../components/SearchContainer"
import { css } from "@emotion/core"

export default function Workspace({ data }) {
  return (
    <Layout>
      <h1>Workspace {data.site.siteMetadata.title}</h1>
      <p>
        This is where I am going to display my projects that I have worked on
        during my time in school! I plan on displaying all of the plugins that I
        am capable of using within Gatsby and React. Soon I will add the
        functionality of a search bar that will bring results in of different
        plugins that I have been able to use.
      </p>
      <SearchContainer
        css={css`
          textdecoration: none;
          color: inherit;
        `}
        css={css`
          text decoration: none;
          color: inherit;
      `}
      ></SearchContainer>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
